import get from 'lodash/get'
import { initialAuthState } from '../contexts/auth'
import { getErrorMessage } from './form'

export const handleErrorResponse = (err, setAuth, errMsg) => {
  if (get(err, 'response.status') === 401) {
    alert('你已被登出，請登入以繼續')
    setAuth(initialAuthState)
  } else {
    alert(errMsg || getErrorMessage(err))
  }
}
