import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { palette } from 'styled-theme'
import { ifProp } from 'styled-tools'

const StyledInput = styled.input`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 0.125rem solid ${ifProp('invalid', palette('error', 0), palette('primary', 0))};
  background: ${ifProp('readOnly', palette('grayscale', 4), palette('white', 0))}${ifProp('readOnly', '80', '')};
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  :checked {
    background: ${ifProp('readOnly', palette('grayscale', 4), palette('primary', 0))}${ifProp('readOnly', '80', '')};
    ::after {
      content: '';
      position: absolute;
      top: 0;
      left: 25%;
      width: 0.3rem;
      height: 0.7rem;
      transform: rotate(45deg);
      border-style: solid;
      border-width: 0 0.3rem 0.3rem 0;
      border-color: ${ifProp('readOnly', palette('grayscale', 2), palette('white', 0))};
    }
  }
`

const Checkbox = ({ checked, onChange, invalid, readOnly, ...props }) => {
  return (
    <StyledInput
      type="checkbox"
      checked={checked}
      onChange={(e) => (!readOnly ? onChange(e.target.checked) : null)}
      invalid={invalid}
      readOnly={readOnly}
      {...props}
    />
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  readOnly: PropTypes.bool,
}

Checkbox.defaultProps = {
  checked: false,
  onChange: () => {},
  invalid: false,
  readOnly: false,
}

export default Checkbox
