import { useEffect } from 'react'

const convertHTMLCollectionToArray = (htmlCollection) => {
  const arr = []
  for (let i = 0; i < htmlCollection.length; i += 1) {
    arr.push(htmlCollection.item(i))
  }
  return arr
}

const dispatchDOMContentLoadedEvent = () => {
  const documentEvent = document.createEvent('Event')
  documentEvent.initEvent('DOMContentLoaded', false, false)
  window.dispatchEvent(documentEvent)
}

export const generateNewBuyMeCoffeeScriptTag = (x, y) => {
  const tag = document.createElement('script')
  tag.dataset.name = 'BMC-Widget'
  tag.dataset.cfasync = false
  tag.src = 'https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js'
  tag.dataset.id = 'crush_hk'
  tag.dataset.description = '請Crush仔飲杯咖啡啦!'
  tag.dataset.message = '請Crush仔飲杯咖啡啦!'
  tag.dataset.color = '#8B57F8'
  tag.dataset.position = 'Right'
  tag.dataset.x_margin = x
  tag.dataset.y_margin = y
  tag.async = true
  return tag
}

export const useBuyMeACoffeeWidget = (x = 12, y = 12) => {
  useEffect(() => {
    const oriBodyChildren = convertHTMLCollectionToArray(document.body.children)
    const existingScriptTag = document.querySelector('script[data-name="BMC-Widget"]')
    if (existingScriptTag) {
      existingScriptTag.dataset.x_margin = x
      existingScriptTag.dataset.y_margin = y
      dispatchDOMContentLoadedEvent()
    } else {
      const newScriptTag = generateNewBuyMeCoffeeScriptTag(x, y)
      newScriptTag.onload = () => {
        dispatchDOMContentLoadedEvent()
      }
      document.head.appendChild(newScriptTag)
    }
    return () => {
      const bodyChildren = convertHTMLCollectionToArray(document.body.children)
      bodyChildren.forEach((bodyChild) => {
        const isOriExist = oriBodyChildren.includes(bodyChild)
        if (!isOriExist) {
          document.body.removeChild(bodyChild)
        }
      })
    }
  }, [x, y])
}
