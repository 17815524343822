import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@rebass/grid'
import Text from '../../atoms/Text'
import Checkbox from '../../atoms/Checkbox'

const CheckboxSelect = ({ options, value, onChange, invalid, readOnly, ...props }) => {
  return (
    <Flex alignItems="center">
      {(options || []).map((option, oIdx) => (
        <Flex key={oIdx} ml={oIdx > 0 ? '2rem' : 0} alignItems="center">
          <Checkbox
            checked={value === option.value}
            onChange={(v) => onChange(v ? option.value : null)}
            invalid={invalid}
            readOnly={readOnly}
            {...props}
          />
          <Text palette="primary" paletteIndex={1} bold>&nbsp;{option.label}</Text>
        </Flex>
      ))}
    </Flex>
  )
}

CheckboxSelect.propTypes = {
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  readOnly: PropTypes.bool,
}

CheckboxSelect.defaultProps = {
  value: null,
  options: [],
  onChange: () => {},
  invalid: false,
  readOnly: false,
}

export default CheckboxSelect
