import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { size } from 'styled-theme'

const Viewport = styled(Flex)`
  justify-content: center;
  max-width: ${size('maxWidth')};
  width: 100%;
`

export default Viewport
