import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { palette } from 'styled-theme'
import { Flex, Box } from '@rebass/grid'
import { BiReset } from 'react-icons/bi'
import { TbMessageChatbot } from 'react-icons/tb'
import { IoArrowUpCircleOutline } from 'react-icons/io5'
import Text from '../../atoms/Text'
import Select from '../../atoms/Select'
import Button from '../../atoms/Button'
import Tooltip from '../../atoms/Tooltip'
import Modal from '../../molecules/Modal'
import Message from '../../molecules/Message'
import MessageSender, { ERROR_QUOTA_EXCEEDED } from '../../molecules/MessageSender'
import TopUpPayment from '../TopUpPayment'

const ChatHistoryContainer = styled(Flex)`
  flex-direction: column;
  flex: 1 1 auto;
  padding-bottom: 56px;
  overflow: auto;
`

const MessageWrapper = styled(Flex)`
  overflow-anchor: none;
  margin-bottom: 0.25rem;
  justify-content: center;
`

const AnchorDiv = styled.div`
  min-height: 1px;
  overflow-anchor: auto;
`

const SystemMessage = styled(Flex)`
  background: ${palette('primary', 3)};
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.0625rem 0.125rem ${palette('grayscale', 2)};
`

const Chatroom = ({
  mode,
  userId,
  messages,
  onChangeMode,
  messageQuota,
  messageRemaining,
  customSection,
  onMessageSend,
  onReset,
  readOnly,
  inputDisabled,
  resetRecommended,
  resetRecommendationContent,
  headerHidden,
  resettable,
  style,
  ...props
}) => {
  const bottomAnchorRef = useRef()
  const [topUpModalOpen, setTopUpModalOpen] = useState(false)
  const theme = useTheme()

  const onClickSend = useCallback(async (message) => {
    if (messageRemaining <= 0) {
      setTopUpModalOpen(true)
      throw new Error(ERROR_QUOTA_EXCEEDED)
    }
    return onMessageSend(message)
  }, [messageRemaining, onMessageSend])

  useEffect(() => {
    if (bottomAnchorRef.current && messages.length > 0) {
      bottomAnchorRef.current.scrollIntoView()
    }
  }, [messages])

  return (
    <Flex flexDirection="column" style={{ position: 'relative', ...style }} {...props}>
      {!headerHidden && (
        <Flex justifyContent="space-between" alignItems="center" mb="0.5rem" ml="0.5rem">
          <Flex alignItems="center">
            <Text variant="body1" style={{ marginRight: 4 }}>模式: </Text>
            <Select
              size="small"
              value={mode}
              onChange={onChangeMode}
              options={[
                { label: 'Match人 👩‍❤️‍👨', value: 'users' },
                { label: '搵野搞 🎉', value: 'events' },
              ]}
            />
          </Flex>
          <Flex alignItems="flex-end">
            <TbMessageChatbot size={24} />
            <Text variant="body1">: </Text>
            <Box mx="0.4rem">
              <Text
                variant="h3"
                id="message_remaining_count"
                bold
                {...(messageRemaining <= 0 ? {
                  palette: 'error',
                  className: 'blink',
                } : {})}
              >
                {messageRemaining}
              </Text>
              {messageRemaining <= 0 && (
                <Tooltip
                  place="left"
                  anchorId="message_remaining_count"
                  defaultIsOpen
                >
                  <Text palette="white">放心！今晚會加返比你！</Text>
                </Tooltip>
              )}
            </Box>
            <Text variant="body1">{`/ ${messageQuota}`}</Text>
            <Button
              padding={0}
              variant="text"
              id="top_up_btn"
              onClick={() => setTopUpModalOpen(true)}
              style={{ marginLeft: '0.2rem' }}
            >
              <IoArrowUpCircleOutline size={24} />
            </Button>
            {messageRemaining <= 0 && (
              <Tooltip
                place="bottom-end"
                color={theme.palette.secondary[2]}
                anchorId="top_up_btn"
                defaultIsOpen
              >
                <Text palette="white">{'唔夠用？\n立即增值亦得！'}</Text>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      )}
      <ChatHistoryContainer>
        {messages.map((message, i) => {
          const isMyMessage = (message.sender?.user_id === userId)
          const isSystemMessage = (message.sender?.user_id === 0)
          return (
            <MessageWrapper key={`message-${i}`} mt="0.4rem">
              {isSystemMessage ? (
                <SystemMessage>
                  <Text align="center">{message.content}</Text>
                </SystemMessage>
              ) : (
                <>
                  {isMyMessage && <Box flex="1 1 0" />}
                  <Message
                    message={message}
                    align={isMyMessage ? 'right' : 'left'}
                  />
                  {isMyMessage || <Box flex="1 1 0" />}
                </>
              )}
            </MessageWrapper>
          )
        })}
        {customSection}
        <AnchorDiv ref={bottomAnchorRef} />
      </ChatHistoryContainer>
      <Flex mt="0.5rem" style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
        {resettable && (
          <Flex mr="0.25rem">
            <Button
              padding="0.625rem"
              palette="grayscale"
              onClick={onReset}
              disabled={readOnly}
              id="chat_reset_btn"
              {...(resetRecommended && !readOnly ? {
                className: 'blink',
              } : {})}
            >
              <BiReset size={24} />
            </Button>
          </Flex>
        )}
        <MessageSender
          flex={1}
          onSend={onClickSend}
          disabled={readOnly || inputDisabled}
        />
      </Flex>
      {resetRecommended && (
        <Tooltip
          place="top-start"
          anchorId="chat_reset_btn"
          defaultIsOpen
        >
          {resetRecommendationContent}
        </Tooltip>
      )}
      <Modal
        showCloseBtn
        isOpen={topUpModalOpen}
        onClose={() => setTopUpModalOpen(false)}
      >
        <Flex mb="1rem">
          <TopUpPayment onComplete={() => setTopUpModalOpen(false)} />
        </Flex>
      </Modal>
    </Flex>
  )
}

Chatroom.propTypes = {
  messages: PropTypes.array,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mode: PropTypes.oneOf(['users', 'events']),
  onChangeMode: PropTypes.func,
  messageQuota: PropTypes.number,
  messageRemaining: PropTypes.number,
  customSection: PropTypes.node,
  onMessageSend: PropTypes.func,
  onReset: PropTypes.func,
  readOnly: PropTypes.bool,
  inputDisabled: PropTypes.bool,
  resetRecommended: PropTypes.bool,
  resetRecommendationContent: PropTypes.node,
  headerHidden: PropTypes.bool,
  resettable: PropTypes.bool,
  style: PropTypes.object,
}

Chatroom.defaultProps = {
  messages: [],
  userId: null,
  mode: 'users',
  onChangeMode: () => {},
  messageQuota: 0,
  messageRemaining: 0,
  customSection: null,
  onMessageSend: () => {},
  onReset: () => {},
  readOnly: false,
  inputDisabled: false,
  resetRecommended: false,
  resetRecommendationContent: '',
  headerHidden: false,
  resettable: false,
  style: {},
}

export default Chatroom
