import React, { useCallback } from 'react'
import get from 'lodash/get'
import { Formik } from 'formik'
import { Box, Flex } from '@rebass/grid'
import styled from 'styled-components'
import { size } from 'styled-theme'
import { useNavigate } from 'react-router-dom'
import Text from '../../atoms/Text'
import Input from '../../atoms/Input'
import Button from '../../atoms/Button'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'
import { forgotPassword } from '../../../api/auth'
import { getErrorMessage, isValidEmail } from '../../../utlils/form'
import { useLoading } from '../../../contexts/loading'

const StyledViewport = styled(Viewport)`
 padding: 8rem 2rem 3rem;
 align-items: flex-start;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const formInitialValues = {
  email: '',
}

const formValidation = (values) => {
  const { email } = values
  const errors = {}
  if (!email) {
    errors.email = '必須填寫'
  } else if (!isValidEmail(email)) {
    errors.email = '電郵格式不正確'
  }
  return errors
}

const ForgotPasswordPage = () => {
  const navigate = useNavigate()
  const { setLoading } = useLoading()

  const onSubmit = useCallback((values, { setFieldError }) => {
    setLoading(true)
    return forgotPassword(values.email)
      .then(({ data }) => {
        alert('密碼重設電郵已經發送至你的 E-mail，請檢查你的郵箱！\n如電郵未出現在您的收件匣中，你可能需要查看濫發郵件。')
        navigate(-1)
      })
      .catch((err) => {
        setFieldError('form', getErrorMessage(err))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [navigate, setLoading])

  return (
    <StyledViewport>
      <ContentBox>
        <Flex justifyContent="center" mb="2rem">
          <Text palette="primary" bold variant="h1">重設密碼</Text>
        </Flex>
        <Formik
          validate={formValidation}
          initialValues={formInitialValues}
          onSubmit={onSubmit}
        >
          {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Text palette="primary">請輸入你的E-mail</Text>
                <Box mt="0.4rem">
                  <Input
                    name="email"
                    placeholder="E-mail *"
                    type="email"
                    value={get(values, 'email', '')}
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    invalid={touched.email && !!errors.email}
                  />
                  <Box ml="0.8rem">
                    <Text variant="body3" palette="error">{touched.email && !!errors.email ? errors.email : ' '}</Text>
                  </Box>
                </Box>
                {!!errors.form && (
                  <Box ml="0.8rem">
                    <Text palette="error">{errors.form}</Text>
                  </Box>
                )}
                <Flex mt="3rem">
                  <Button
                    type="submit"
                    disabled={!values.email || isSubmitting}
                    fullWidth
                  >
                    確定重設密碼
                  </Button>
                </Flex>
                <Flex mt="1rem">
                  <Button variant="outline" to={-1} fullWidth>
                    返回
                  </Button>
                </Flex>
              </form>
            )
          }}
        </Formik>
      </ContentBox>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default ForgotPasswordPage
