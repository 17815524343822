/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react'
import { font, palette as styledPalette } from 'styled-theme'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { switchProp, ifProp, prop } from 'styled-tools'

export const formatLineBreak = (str) => (str || '').split('\n').map(
  (strItem, i) => (
    <React.Fragment key={i}>
      {i > 0 && <br />}
      {strItem}
    </React.Fragment>
  ),
)

const TextWrap = ({ palette, paletteIndex, variant, bold, align, ...props }) => {
  switch (variant) {
    case 'h1':
      return <h1 {...props} />
    case 'h2':
      return <h2 {...props} />
    case 'h3':
      return <h3 {...props} />
    default:
      return <span {...props} />
  }
}

TextWrap.propTypes = {
  palette: PropTypes.string,
  paletteIndex: PropTypes.number,
  variant: PropTypes.string,
  bold: PropTypes.bool,
  align: PropTypes.string,
}

const StyledWrap = styled(TextWrap)`
  display: inline-block;
  font-family: ${font('primary')};
  color: ${({ palette, paletteIndex }) => styledPalette(palette, paletteIndex)};
  font-size: ${switchProp('variant', {
    h1: '2.25rem',
    h2: '1.875rem',
    h3: '1.5rem',
    body1: '1rem',
    body2: '0.85028rem',
    body3: '0.78405rem',
  })};
  line-height: 1.2;
  text-align: ${prop('align')};
  font-weight: ${ifProp('bold', 'bold', 'normal')};
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
`

const Text = ({ variant, palette, paletteIndex, bold, align, children, ...props }) => {
  return (
    <StyledWrap
      variant={variant}
      palette={palette}
      paletteIndex={paletteIndex}
      bold={bold}
      align={align}
      {...props}
    >
      {typeof children === 'string' ? formatLineBreak(children) : children}
    </StyledWrap>
  )
}

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  variant: PropTypes.string,
  palette: PropTypes.string,
  paletteIndex: PropTypes.number,
  bold: PropTypes.bool,
  align: PropTypes.string,
}

Text.defaultProps = {
  children: null,
  variant: 'body1',
  palette: 'grayscale',
  paletteIndex: 0,
  bold: false,
}

export default Text
