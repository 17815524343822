import api from '..'

export const getEvents = (params) => {
  return api.get('/events', params, 'bearer')
}

export const getEvent = (id, params) => {
  return api.get(`/events/${id}`, params, 'bearer')
}

export const createEvent = (body) => {
  return api.post('/events', body, 'bearer')
}

export const updateEvent = (id, body) => {
  return api.put(`/events/${id}`, body, 'bearer')
}

export const deleteEvent = (id) => {
  return api.delete(`/events/${id}`, 'bearer')
}
