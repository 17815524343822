import qs from 'qs'
import axios from 'axios'
import get from 'lodash/get'
import config from '../config'

const baseUrl = config.apiUrl

const getOAuthClientCredential = () => ({
  username: config.oauthClientId,
  password: config.oauthClientSecret,
})

export const getStoredAuth = () => {
  let localStorageAuth = null
  if (localStorage.getItem('auth')) {
    try {
      localStorageAuth = JSON.parse(localStorage.getItem('auth'))
    } catch (err) {
      localStorageAuth = null
    }
  }
  return localStorageAuth
}

export const setStoredAuth = (auth) => {
  localStorage.setItem('auth', JSON.stringify(auth))
}

const getRequestConfig = (authType, contentType) => {
  const auth = authType === 'bearer' ? getStoredAuth() : null
  const token = get(auth, 'access_token')
  return {
    ...(authType === 'basic' ? { auth: getOAuthClientCredential() } : {}),
    headers: {
      'Accept': 'application/json',
      'Content-Type': contentType || 'application/json',
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  }
}

const api = {
  get: (url, params, authType) => {
    const fullUrl = `${baseUrl}${url}`
    const requestConfig = {
      ...getRequestConfig(authType),
      paramsSerializer: qs.stringify,
      params,
    }
    return axios.get(fullUrl, requestConfig)
  },
  post: (url, body, authType) => {
    const fullUrl = `${baseUrl}${url}`
    const data = JSON.stringify(body)
    const requestConfig = getRequestConfig(authType)
    return axios.post(fullUrl, data, requestConfig)
  },
  put: (url, body, authType) => {
    const fullUrl = `${baseUrl}${url}`
    const data = JSON.stringify(body)
    const requestConfig = getRequestConfig(authType)
    return axios.put(fullUrl, data, requestConfig)
  },
  delete: (url, authType) => {
    const fullUrl = `${baseUrl}${url}`
    const requestConfig = getRequestConfig(authType)
    return axios.delete(fullUrl, requestConfig)
  },
}

export default api
