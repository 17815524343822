import api from '..'

export const getChats = (params) => {
  return api.get('/chats', params, 'bearer')
}

export const getChat = (id, params) => {
  return api.get(`/chats/${id}`, params, 'bearer')
}

export const getMessgaes = (params) => {
  return api.get('/messages', params, 'bearer')
}

export const getChatMembers = (params) => {
  return api.get('/chat-members', params, 'bearer')
}

export const sendMessage = (chatId, body) => {
  return api.post(`/chats/${chatId}/send_message`, body, 'bearer')
}

export const markChatAsRead = (chatId, body) => {
  return api.post(`/chats/${chatId}/mark_as_read`, body, 'bearer')
}

export const restartChat = (chatId, body) => {
  return api.post(`/chats/${chatId}/restart`, body, 'bearer')
}
