import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@rebass/grid'
import { loadStripe } from '@stripe/stripe-js'
import { useTheme } from 'styled-components'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import Text from '../../atoms/Text'
import Image from '../../atoms/Image'
import Button from '../../atoms/Button'
import SuccessIcon from '../../atoms/SuccessIcon'
import config from '../../../config'

const stripePromise = loadStripe(config.stripePublishableKey, { locale: 'zh-HK' })

const StripePaymentInnerForm = ({ onConfirmSuccess }) => {
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadedError, setLoadedError] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const stripePaymentResult = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: window.location.href,
        },
      })
      const { paymentIntent, error } = stripePaymentResult
      if (error) {
        throw error
      } else if (paymentIntent.status === 'succeeded') {
        setSuccess(true)
      }
    } catch (err) {
      alert(err.message)
    } finally {
      setLoading(false)
    }
  }, [elements, setLoading, stripe])

  return (
    <form onSubmit={onSubmit} style={{ width: '100%' }}>
      {success ? (
        <Flex flexDirection="column" alignItems="center">
          <Box width="40%" maxWidth={180} minWidth={120} style={{ aspectRatio: 1 }}>
            <SuccessIcon />
          </Box>
          <Box mt="1rem">
            <Text palette="primary" bold variant="h1" align="center">
              付款成功
            </Text>
          </Box>
          <Box mt="1rem">
            <Text
              palette="primary"
              paletteIndex={3}
              variant="h3"
              align="center"
            >
              你已成功增值，<br />現在可以再次使用Crush了！
            </Text>
          </Box>
          <Box mt="2rem">
            <Button onClick={onConfirmSuccess}>知道了</Button>
          </Box>
        </Flex>
      ) : (
        <Flex flexDirection="column" style={{ position: 'relative' }}>
          <PaymentElement
            onLoadError={() => setLoadedError(true)}
            options={{
              business: { name: 'Crush HK' },
              wallets: { applePay: 'auto', googlePay: 'auto' },
            }}
          />
          {loadedError && (
            <Text variant="h3" align="center" palette="error">
              系統有啲野出錯左！<br />請等陣再試&gt;.&lt;
            </Text>
          )}
          <Flex mt="2rem">
            <Button type="submit" disabled={loading || loadedError} fullWidth>
              確認
            </Button>
          </Flex>
          {loading && (
            <Flex
              justifyContent="center"
              alignItems="center"
              style={{
                position: 'fixed',
                inset: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
              }}
            >
              <Image src="/logo_simple.png" height="3rem" className="self_rotate" />
            </Flex>
          )}
        </Flex>
      )}
    </form>
  )
}

StripePaymentInnerForm.propTypes = {
  onConfirmSuccess: PropTypes.func,
}

StripePaymentInnerForm.defaultProps = {
  onConfirmSuccess: () => {},
}

const StripePaymentForm = ({ clientSecret, onConfirmSuccess, ...props }) => {
  const theme = useTheme()
  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: {
          theme: 'stripe',
          labels: 'above',
          variables: {
            borderRadius: '0.5rem',
            fontSizeBase: '1rem',
            fontFamily: theme.fonts.primary,
            colorPrimary: theme.palette.primary[0],
            colorTextPlaceholder: `${theme.palette.primary[4]}99`,
            colorDanger: theme.palette.error[0],
            colorText: theme.palette.grayscale[0],
          },
        },
      }}
      {...props}
    >
      <StripePaymentInnerForm onConfirmSuccess={onConfirmSuccess} />
    </Elements>
  )
}

StripePaymentForm.propTypes = {
  clientSecret: PropTypes.string,
  onConfirmSuccess: PropTypes.func,
}

StripePaymentForm.defaultProps = {
  clientSecret: null,
  onConfirmSuccess: () => {},
}

export default StripePaymentForm
