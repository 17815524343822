import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const AdSense = ({ adSlotId, ...props }) => {
  useEffect(() => {
    try {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    } catch (err) {
      console.error('[AdSense] err', err)
    }
  }, [])

  return (
    <ins
      className="adsbygoogle"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
      data-ad-client="ca-pub-5119586228664681"
      data-full-width-responsive="true"
      data-ad-slot={adSlotId}
      {...props}
    />
  )
}

AdSense.propTypes = {
  adSlotId: PropTypes.string,
}

AdSense.defaultProps = {
  adSlotId: null,
}

export default AdSense
