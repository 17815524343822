import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@rebass/grid'
import Button from '../../atoms/Button'
import Text from '../../atoms/Text'

const POSITIVE_REPLY_MESSAGE = '好呀，就揀佢啦！'
const NEGATIVE_REPLY_MESSAGE = '我對呢位冇興趣，有冇另一個？'

const UserDecisionActionSection = ({ onSendMessage, disabled, ...props }) => {
  return (
    <Flex justifyContent="center" p="1rem" {...props}>
      <Button
        palette="secondary"
        onClick={() => onSendMessage(POSITIVE_REPLY_MESSAGE)}
        disabled={disabled}
      >
        <Text variant="h3" palette={disabled ? 'white' : 'grayscale'}>就佢啦！</Text>
      </Button>
      <Box ml="1rem">
        <Button
          palette="error"
          onClick={() => onSendMessage(NEGATIVE_REPLY_MESSAGE)}
          disabled={disabled}
        >
          <Text variant="h3" palette="white">不了</Text>
        </Button>
      </Box>
    </Flex>
  )
}

UserDecisionActionSection.propTypes = {
  onSendMessage: PropTypes.func,
  disabled: PropTypes.bool,
}

UserDecisionActionSection.defaultProps = {
  onSendMessage: () => {},
  disabled: false,
}

export default UserDecisionActionSection
