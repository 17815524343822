import api from '..'

export const getUserMatches = (params) => {
  return api.get('/user-matches', params, 'bearer')
}

export const getUserMatch = (id, params) => {
  return api.get(`/user-matches/${id}`, params, 'bearer')
}

export const acceptUserMatch = (id, body) => {
  return api.post(`/user-matches/${id}/accept`, body, 'bearer')
}

export const rejectUserMatch = (id, body) => {
  return api.post(`/user-matches/${id}/reject`, body, 'bearer')
}
