import React, { useCallback, useState } from 'react'
import get from 'lodash/get'
import { Box, Flex } from '@rebass/grid'
import styled from 'styled-components'
import { size } from 'styled-theme'
import { useNavigate } from 'react-router-dom'
import Text from '../../atoms/Text'
import Link from '../../atoms/Link'
import Button from '../../atoms/Button'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'
import RegisterForm from '../../organisms/RegisterForm'
import { getErrorMessage } from '../../../utlils/form'
import { useLoading } from '../../../contexts/loading'
import { register } from '../../../api/auth'

const StyledViewport = styled(Viewport)`
 padding: 8rem 2rem 3rem;
 align-items: flex-start;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const RegisterPage = () => {
  const navigate = useNavigate()
  const [registeredUser, setRegisteredUser] = useState(null)
  const { setLoading } = useLoading()

  const goToLogin = useCallback(() => {
    return navigate({
      pathname: '/login',
      search: '?redirect=/profile',
    })
  }, [navigate])

  const onSubmit = useCallback((values, { setFieldError }) => {
    const { username, email, sex, phone_number, password, confirm_password, description } = values
    setLoading(true)
    const body = {
      sex,
      phone_number,
      description,
    }
    return register(username, email, password, confirm_password, body)
      .then(({ data: user }) => {
        setRegisteredUser(user)
      })
      .catch((err) => {
        setFieldError('form', getErrorMessage(err))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [setLoading])

  return (
    <StyledViewport>
      <ContentBox>
        {!registeredUser ? (
          <>
            <Flex justifyContent="center" mb="2rem">
              <Text palette="primary" bold variant="h1">新用戶註冊</Text>
            </Flex>
            <RegisterForm onRegister={onSubmit} />
          </>
        ) : (
          <Flex flexDirection="column" alignItems="center">
            <Text palette="primary" bold variant="h1" align="center">
              多謝你嘅註冊，
              <span style={{ wordBreak: 'keep-all', textWrap: 'nowrap' }}>{get(registeredUser, 'username')}！</span>
            </Text>
            <Box mt="1rem">
              <Text palette="primary" bold variant="h2" align="center">
                Crush A.I. 愛情顧問已經等緊你！
              </Text>
            </Box>
            <Box mt="2.8rem">
              <Text variant="h3" align="center">
                仲唔快啲follow 我哋IG&nbsp;
                <Link href="https://www.instagram.com/crushk.co">@crushk.co</Link>
                &nbsp;緊貼我哋最新消息！
              </Text>
            </Box>
            <Box mt="1rem">
              <Text variant="h3" align="center">
                你亦可以上&nbsp;
                <Link href="https://www.crushk.co">crushk.co</Link>
                &nbsp;了解更多關於<b>Crush</b>嘅故事。我哋期待你喺依度搵到真愛。
              </Text>
            </Box>
            <Box mt="1rem">
              <Text variant="body1" align="center">
                合作 / 查詢: <Link href="mailto:contact@crushk.co">contact@crushk.co</Link>
              </Text>
            </Box>
            <Box mt="2rem">
              <Button onClick={goToLogin}>立即登入</Button>
            </Box>
          </Flex>
        )}
      </ContentBox>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default RegisterPage
