import React, { useCallback, useEffect } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { size } from 'styled-theme'
import Text from '../../atoms/Text'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'
import EditProfileForm from '../../organisms/EditProfileForm'
import { useBuyMeACoffeeWidget } from '../../../services/buymeacoffee'
import { getStoredAuth } from '../../../api'
import { getMe, updateUser } from '../../../api/users'
import { requestVerifyEmail } from '../../../api/auth'
import { handleErrorResponse } from '../../../utlils/general'
import { useLoading } from '../../../contexts/loading'
import { useAuth } from '../../../contexts/auth'
import { getErrorMessage } from '../../../utlils/form'

const StyledViewport = styled(Viewport)`
  padding: 4.5rem 3rem 3rem;
  align-items: flex-start;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const ProfilePage = () => {
  const { auth, setAuth } = useAuth()
  const { setLoading } = useLoading()

  useBuyMeACoffeeWidget()

  const fetchUserFromApi = useCallback(() => {
    const userParams = {}
    setLoading(true)
    return getMe(userParams)
      .then(({ data: user }) => {
        const newAuth = { ...getStoredAuth(), user }
        setAuth(newAuth)
      })
      .catch(((err) => {
        handleErrorResponse(err, setAuth)
      }))
      .finally(() => {
        setLoading(false)
      })
  }, [setLoading, setAuth])

  const updateUserToApi = useCallback((values, { setFieldError }) => {
    const updateBody = {
      username: values.username,
      email: values.email,
      display_name: values.display_name,
      sex: values.sex,
      phone_number: values.phone_number,
      date_of_birth: values.date_of_birth ? moment(values.date_of_birth).valueOf() : null,
      height: values.height,
      weight: values.weight,
      target_sex: values.target_sex,
      contact_info: values.contact_info,
      description: values.description,
      active: values.active,
    }
    setLoading(true)
    return updateUser(auth.user?.username, updateBody)
      .then(({ data: updatedUser }) => {
        const newAuth = { ...getStoredAuth(), user: updatedUser }
        setAuth(newAuth)
      })
      .catch(((err) => {
        setFieldError('form', getErrorMessage(err))
        handleErrorResponse(err, setAuth)
      }))
      .finally(() => {
        setLoading(false)
      })
  }, [auth.user, setLoading, setAuth])

  const sendVerifyEmailRequestToApi = useCallback(() => {
    setLoading(true)
    return requestVerifyEmail()
      .then(({ data }) => {
        if (data.status === 'success') {
          alert('驗證電郵已發送到你的E-mail，請檢查郵箱📪')
        }
      })
      .catch(((err) => {
        handleErrorResponse(err, setAuth)
      }))
      .finally(() => {
        setLoading(false)
      })
  }, [setAuth, setLoading])

  useEffect(() => {
    fetchUserFromApi()
  }, [fetchUserFromApi])

  return (
    <StyledViewport>
      <ContentBox>
        <Flex justifyContent="center" mb="2rem">
          <Text palette="primary" bold variant="h1">個人資料</Text>
        </Flex>
        <EditProfileForm
          user={auth.user}
          onSave={updateUserToApi}
          onRequestVerifyEmail={sendVerifyEmailRequestToApi}
        />
      </ContentBox>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default ProfilePage
