import React, { useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { getStoredAuth, setStoredAuth } from '../api'

const AuthContext = React.createContext()
const { Provider, Consumer: AuthConsumer } = AuthContext

export const initialAuthState = {
  user: null,
}

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(getStoredAuth() || initialAuthState)

  const setPersistAuth = useCallback((newAuth) => {
    setAuth((oriAuth) => {
      const updatedAuth = (typeof newAuth === 'function') ? newAuth(oriAuth) : newAuth
      setStoredAuth(updatedAuth)
      return updatedAuth
    })
  }, [])

  const setAuthUser = useCallback((newUser) => {
    setPersistAuth((oriAuth) => {
      const updatedUser = (typeof newUser === 'function') ? newUser(oriAuth.user) : newUser
      return { ...oriAuth, user: updatedUser }
    })
  }, [setPersistAuth])

  return (
    <Provider value={{ auth, setAuth: setPersistAuth, setAuthUser }}>
      {children}
    </Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node,
}

export const withAuth = (Component) => {
  return (props) => (
    <AuthConsumer>
      {({ auth, setAuth, setAuthUser }) => (
        <Component
          auth={auth}
          setAuth={setAuth}
          setAuthUser={setAuthUser}
          {...props}
        />
      )}
    </AuthConsumer>
  )
}

export const useAuth = () => useContext(AuthContext)

export default AuthContext
