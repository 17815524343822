import { Flex } from '@rebass/grid'
import styled from 'styled-components'
import { palette } from 'styled-theme'

const SuccessIcon = styled(Flex)`
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  border: 0.4rem solid ${palette('success', 1)};
  background: ${palette('success', 2)};
  ::after {
    content: '';
    width: 20%;
    height: 42%;
    margin-bottom: 5%;
    transform: rotate(45deg);
    border-style: solid;
    border-width: 0 0.8rem 0.8rem 0;
    border-color: ${palette('white', 0)};
  }
`

export default SuccessIcon
