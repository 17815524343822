import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@rebass/grid'
import styled, { css, useTheme } from 'styled-components'
import { font, palette } from 'styled-theme'
import { ifProp } from 'styled-tools'

const inputStyles = css`
  box-sizing: border-box;
  font-size: 1rem;
  display: block;
  width: 100%;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  line-height: 1.2;
  border-radius: 0.5rem;
  outline: 0;
  border: 0.125rem solid transparent;
  caret-color: ${ifProp('invalid', palette('error', 0), palette('primary', 0))};
  background: ${ifProp('readOnly', palette('grayscale', 4), palette('white', 0))}${ifProp('readOnly', '80', '')};
  font-family: ${font('primary')};
  color: ${palette('grayscale', 0)};
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${palette('primary', 4)};
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${palette('primary', 4)};
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: ${palette('primary', 4)};
  }
`
const StyledInput = styled.input`
  ${inputStyles}
`

const StyledTextArea = styled.textarea`
  ${inputStyles}
  resize: vertical;
`

export const StyledFieldset = styled.fieldset`
  width: 100%;
  display: block;
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  border: 0.125rem solid ${ifProp('invalid', palette('error', 0), palette('primary', 0))};
  margin-inline: 0;
  pointer-events: none;
`

const lengendFontSize = 12

const Input = ({ label, value, onChange, type, invalid, readOnly, placeholder, ...props }) => {
  const theme = useTheme()
  const InputComponent = type === 'textarea' ? StyledTextArea : StyledInput
  return (
    <Flex style={{ position: 'relative', width: '100%' }}>
      <InputComponent
        value={value}
        onChange={onChange}
        type={type}
        readOnly={readOnly}
        invalid={invalid}
        placeholder={placeholder}
        {...props}
      />
      <StyledFieldset invalid={invalid} style={{ top: label ? -(lengendFontSize / 2 - 1) : 0 }}>
        {!!label && (
          <legend style={{ lineHeight: 1, fontSize: lengendFontSize, color: theme.palette.primary[0] }}>
            {label}
          </legend>
        )}
      </StyledFieldset>
    </Flex>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  readOnly: PropTypes.bool,
}

Input.defaultProps = {
  label: '',
  value: '',
  onChange: () => {},
  type: 'text',
  placeholder: null,
  invalid: false,
  readOnly: false,
}

export default Input
