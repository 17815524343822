import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Formik } from 'formik'
import { Box, Flex } from '@rebass/grid'
import Text from '../../atoms/Text'
import Input from '../../atoms/Input'
import Button from '../../atoms/Button'
import CheckboxSelect from '../../molecules/CheckboxSelect'
import { isValidEmail } from '../../../utlils/form'

const formInitialValues = {
  username: '',
  email: '',
  sex: null,
  phone_number: '',
  password: '',
  confirm_password: '',
  description: '',
}

const formValidation = (values) => {
  const { username, email, sex, phone_number, password, confirm_password } = values
  const errors = {}
  if (!username) {
    errors.username = '必須填寫'
  } else if (username.length < 6) {
    errors.username = '必須長過6個字元'
  } else if (!/^[a-zA-Z0-9]+$/.test(username)) {
    errors.username = '必須由英文字母或數字組成'
  }
  if (!email) {
    errors.email = '必須填寫'
  } else if (!isValidEmail(email)) {
    errors.email = '電郵格式不正確'
  }
  if (!sex) {
    errors.sex = '必須填寫'
  } else if (!['M', 'F'].includes(sex)) {
    errors.sex = '性別不支援'
  }
  if (!phone_number) {
    errors.phone_number = '必須填寫'
  } else if (!/^([+]?852)?[ -]*[2-9][0-9]{3}[ -]*[0-9]{4}$/.test(phone_number)) {
    errors.phone_number = '電話號碼不正確'
  }
  if (!password) {
    errors.password = '必須填寫'
  }
  if (!confirm_password) {
    errors.confirm_password = '必須填寫'
  } else if (password !== confirm_password) {
    errors.confirm_password = '密碼不一致'
  }
  return errors
}

const RegisterForm = ({ onRegister, ...props }) => {
  return (
    <Formik
      validate={formValidation}
      initialValues={formInitialValues}
      onSubmit={onRegister}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, touched }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box mt="0.6rem">
              <Input
                name="username"
                label={get(values, 'username') ? '用戶ID *' : null}
                placeholder="用戶ID *"
                value={get(values, 'username', '')}
                onChange={handleChange('username')}
                onBlur={handleBlur('username')}
                invalid={touched.username && !!errors.username}
              />
              <Box ml="0.8rem">
                <Text variant="body3" palette="error">{touched.username && !!errors.username ? errors.username : ' '}</Text>
              </Box>
            </Box>
            <Box mt="0.2rem">
              <Input
                name="email"
                label={get(values, 'email') ? 'E-mail *' : null}
                placeholder="E-mail *"
                type="email"
                value={get(values, 'email', '')}
                onChange={handleChange('email')}
                onBlur={handleBlur('email')}
                invalid={touched.email && !!errors.email}
              />
              <Box ml="0.8rem">
                <Text variant="body3" palette="error">{touched.email && !!errors.email ? errors.email : ' '}</Text>
              </Box>
            </Box>
            <Box my="0.4rem">
              <Flex alignItems="center">
                <Text palette="primary" paletteIndex={4}>性別 *</Text>
                <Flex ml="2rem">
                  <CheckboxSelect
                    value={values.sex}
                    options={[
                      { label: '男', value: 'M' },
                      { label: '女', value: 'F' },
                    ]}
                    onChange={(v) => setFieldValue('sex', v)}
                    onBlur={handleBlur('sex')}
                    invalid={touched.sex && !!errors.sex}
                  />
                </Flex>
              </Flex>
              <Box ml="5rem">
                <Text variant="body3" palette="error">{touched.sex && !!errors.sex ? errors.sex : ' '}</Text>
              </Box>
            </Box>
            <Box mt="0.2rem">
              <Input
                name="phone_number"
                label={get(values, 'phone_number') ? '電話號碼 *' : null}
                placeholder="電話號碼 *"
                type="tel"
                value={get(values, 'phone_number', '')}
                onChange={handleChange('phone_number')}
                onBlur={handleBlur('phone_number')}
                invalid={touched.phone_number && !!errors.phone_number}
              />
              <Box ml="0.8rem">
                <Text variant="body3" palette="error">{touched.phone_number && !!errors.phone_number ? errors.phone_number : ' '}</Text>
              </Box>
            </Box>
            <Box mt="0.2rem">
              <Input
                name="password"
                label={get(values, 'password') ? '密碼 *' : null}
                placeholder="密碼 *"
                type="password"
                value={get(values, 'password', '')}
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                invalid={touched.password && !!errors.password}
              />
              <Box ml="0.8rem">
                <Text variant="body3" palette="error">{touched.password && !!errors.password ? errors.password : ' '}</Text>
              </Box>
            </Box>
            <Box mt="0.2rem">
              <Input
                name="confirm_password"
                label={get(values, 'confirm_password') ? '確認密碼 *' : null}
                placeholder="確認密碼 *"
                type="password"
                value={get(values, 'confirm_password', '')}
                onChange={handleChange('confirm_password')}
                onBlur={handleBlur('confirm_password')}
                invalid={touched.confirm_password && !!errors.confirm_password}
              />
              <Box ml="0.8rem">
                <Text variant="body3" palette="error">{touched.confirm_password && !!errors.confirm_password ? errors.confirm_password : ' '}</Text>
              </Box>
            </Box>
            <Box mt="0.2rem">
              <Input
                name="description"
                label={get(values, 'description') ? '個人簡介' : null}
                placeholder={'個人簡介\n( 提示：打得愈多野愈易比人搵到你！)'}
                type="textarea"
                value={get(values, 'description', '')}
                onChange={handleChange('description')}
                onBlur={handleBlur('description')}
                invalid={touched.description && !!errors.description}
                rows={10}
              />
              <Box ml="0.8rem">
                <Text variant="body3" palette="error">{touched.description && !!errors.description ? errors.description : ' '}</Text>
              </Box>
            </Box>
            {!!errors.form && (
              <Box ml="0.8rem">
                <Text palette="error">{errors.form}</Text>
              </Box>
            )}
            <Flex mt="1rem">
              <Button
                type="submit"
                disabled={!values.username || !values.email || !values.sex || !values.password || !values.confirm_password || isSubmitting}
                fullWidth
              >
                確定
              </Button>
            </Flex>
            <Flex mt="1rem">
              <Button variant="outline" to={-1} fullWidth>
                返回
              </Button>
            </Flex>
          </form>
        )
      }}
    </Formik>
  )
}

RegisterForm.propTypes = {
  onRegister: PropTypes.func,
}

RegisterForm.defaultProps = {
  onRegister: () => {},
}

export default RegisterForm
