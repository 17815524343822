import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import { palette as styledPalette } from 'styled-theme'

const StyledA = styled.a`
  text-decoration: none;
  :link {
    color: ${({ palette, paletteIndex }) => styledPalette(palette, paletteIndex)};
  }
  :visited {
    color: ${({ palette, paletteIndex }) => styledPalette(palette, paletteIndex)};
  }
  :hover {
    color: ${({ palette, hoverPaletteIndex }) => styledPalette(palette, hoverPaletteIndex)};
    text-decoration: underline;
  }
  :active {
    color: ${({ palette, activePaletteIndex }) => styledPalette(palette, activePaletteIndex)};
    color: ${styledPalette(prop('palette'), 1)};
  }
`

const Link = ({ children, href, palette, paletteIndex, hoverPaletteIndex, activePaletteIndex, ...props }) => (
  <StyledA
    target="_blank"
    rel="noopener, noreferrer"
    palette={palette}
    paletteIndex={paletteIndex}
    hoverPaletteIndex={hoverPaletteIndex}
    activePaletteIndex={activePaletteIndex}
    href={href}
    {...props}
  >
    {children}
  </StyledA>
)

Link.propTypes = {
  href: PropTypes.string,
  palette: PropTypes.string,
  paletteIndex: PropTypes.number,
  hoverPaletteIndex: PropTypes.number,
  activePaletteIndex: PropTypes.number,
  children: PropTypes.node,
}

Link.defaultProps = {
  href: null,
  palette: 'primary',
  paletteIndex: 0,
  hoverPaletteIndex: 4,
  activePaletteIndex: 1,
  children: null,
}

export default Link
