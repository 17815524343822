const theme = {
  fonts: {
    primary: 'arial, sans-serif',
  },
  palette: {
    primary: ['#8B57F8', '#5249AE', '#6C28FC', '#695496', '#CB73FA', '#DDDAFF'],
    secondary: ['#74FCCE', '#02D991', '#51b09f', '#2F735C', '#bafff2', '#ddfff9'],
    grayscale: ['#000000', '#404040', '#808080', '#A9A9A9', '#D3D3D3', '#F5F5F5'],
    white: ['#FFFFFF'],
    success: ['#00FF00', '#00b300', '#2CDB2C', '#467546', '#bfffbf', '#80ff80'],
    error: ['#FF6666', '#DB2C2C', '#b34747', '#754646', '#ffb3b3', '#ffd9d9'],
  },
  sizes: {
    maxWidth: '1024px',
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px',
  },
}

theme.breakpoints = [
  theme.sizes.mobile,
  theme.sizes.tablet,
]

export default theme
