import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Formik } from 'formik'
import { Box, Flex } from '@rebass/grid'
import Text from '../../atoms/Text'
import Input from '../../atoms/Input'
import Button from '../../atoms/Button'

const formValidation = (values) => {
  const { username, password } = values
  const errors = {}
  if (!username) {
    errors.username = '必須填寫'
  }
  if (!password) {
    errors.password = '必須填寫'
  }
  return errors
}

const LoginForm = ({ initialValues, onLogin, forgotPasswordPath, registerPath, ...props }) => {
  return (
    <Formik
      validate={formValidation}
      initialValues={initialValues}
      onSubmit={onLogin}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box mt="0.6rem">
              <Input
                name="username"
                placeholder="用戶ID"
                value={get(values, 'username', '')}
                onChange={handleChange('username')}
                onBlur={handleBlur('username')}
                invalid={touched.username && !!errors.username}
              />
              <Box ml="0.8rem">
                <Text variant="body3" palette="error">{touched.username && !!errors.username ? errors.username : ' '}</Text>
              </Box>
            </Box>
            <Box mt="0.2rem">
              <Input
                name="password"
                placeholder="密碼"
                type="password"
                value={get(values, 'password', '')}
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                invalid={touched.password && !!errors.password}
              />
              <Box ml="0.8rem">
                <Text variant="body3" palette="error">{touched.password && !!errors.password ? errors.password : ' '}</Text>
              </Box>
            </Box>
            {!!errors.form && (
              <Box ml="0.8rem">
                <Text palette="error">{errors.form}</Text>
              </Box>
            )}
            <Flex justifyContent="flex-end">
              <Button variant="text" to={forgotPasswordPath}>
                忘記密碼?
              </Button>
            </Flex>
            <Flex mt="0.2rem">
              <Button
                type="submit"
                disabled={!values.username || !values.password || isSubmitting}
                fullWidth
              >
                登入
              </Button>
            </Flex>
            <Flex mt="1rem">
              <Button variant="outline" to={registerPath} fullWidth>
                新用戶註冊
              </Button>
            </Flex>
          </form>
        )
      }}
    </Formik>
  )
}

LoginForm.propTypes = {
  initialValues: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }),
  onLogin: PropTypes.func,
  forgotPasswordPath: PropTypes.string,
  registerPath: PropTypes.string,
}

LoginForm.defaultProps = {
  initialValues: {
    username: '',
    password: '',
  },
  onLogin: () => {},
  forgotPasswordPath: '',
  registerPath: '',
}

export default LoginForm
