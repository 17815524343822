import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import { AuthProvider } from './contexts/auth'
import { LoadingProvider } from './contexts/loading'
import appTheme from './theme'
import App from './App'
import './index.css'

ReactDOM.render((
  <ThemeProvider theme={appTheme}>
    <AuthProvider>
      <BrowserRouter>
        <LoadingProvider>
          <App />
        </LoadingProvider>
      </BrowserRouter>
    </AuthProvider>
  </ThemeProvider>
), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
