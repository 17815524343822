import React from 'react'
import { Flex } from '@rebass/grid'
import AdSense from '../../atoms/AdSense'

const AddSenseGroupView = ({ ...props }) => {
  return (
    <>
      <Flex flexDirection="column" width={200} style={{ position: 'fixed', top: '6rem', bottom: 0, right: 0 }}>
        <AdSense adSlotId="9477289676" />
        <AdSense adSlotId="9477289676" />
      </Flex>
      <Flex flexDirection="column" width={200} style={{ position: 'fixed', top: '6rem', bottom: 0, left: 0 }}>
        <AdSense adSlotId="9477289676" />
        <AdSense adSlotId="9477289676" />
      </Flex>
      <AdSense adSlotId="8291179981" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, height: 200, maxWidth: 1024, margin: 'auto' }} />
    </>
  )
}

export default AddSenseGroupView
