import React from 'react'
import styled from 'styled-components'
import { size } from 'styled-theme'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import TopUpPayment from '../../organisms/TopUpPayment'

const StyledViewport = styled(Viewport)`
 padding: 8rem 2rem 3rem;
 align-items: flex-start;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const PaymentPage = () => {
  return (
    <StyledViewport>
      <ContentBox>
        <TopUpPayment />
      </ContentBox>
    </StyledViewport>
  )
}

export default PaymentPage
