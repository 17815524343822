import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@rebass/grid'
import Image from '../components/atoms/Image'

const LoadingContext = React.createContext()
const { Provider, Consumer: LoadingConsumer } = LoadingContext

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  return (
    <Provider value={{ loading, setLoading }}>
      {children}
      {loading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          style={{
            position: 'fixed',
            inset: 0,
            zIndex: 100,
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          }}
        >
          <Image
            src="/logo_simple.png"
            height="5rem"
            className="self_rotate"
          />
        </Flex>
      )}
    </Provider>
  )
}

LoadingProvider.propTypes = {
  children: PropTypes.node,
}

export const withLoading = (Component) => {
  return (props) => (
    <LoadingConsumer>
      {({ loading, setLoading }) => (
        <Component
          loading={loading}
          setLoading={setLoading}
          {...props}
        />
      )}
    </LoadingConsumer>
  )
}

export const useLoading = () => useContext(LoadingContext)

export default LoadingContext
