import { Flex } from '@rebass/grid'
import styled from 'styled-components'
import { palette, size } from 'styled-theme'

const ContentBox = styled(Flex)`
  background: ${palette('grayscale', 5)};
  flex-direction: column;
  box-shadow: 0 1.0625rem 3.125rem 0 ${palette('grayscale', 2)}, 0 0.75rem 0.9375rem 0 ${palette('grayscale', 2)};
  max-height: 100%;
  overflow: auto;
  width: 100%;
  border-radius: 0.5rem;
  padding: 2rem;
  z-index: 50;
  @media only screen and (max-width: ${size('mobile')}) {
    height: 100%;
  }
`

export default ContentBox
