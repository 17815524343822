import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { palette } from 'styled-theme'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import theme from '../../../theme'

const StyledTooltip = styled(ReactTooltip)`
  z-index: 999;
  box-shadow: 0 0 5px 2px ${palette('primary', 5)};
  .react-tooltip-arrow {
    width: 1.2rem;
    height: 1.2rem;
  }
`

const Tooltip = ({ anchorId, color, place, open, defaultIsOpen, children, style, ...props }) => {
  return (
    <StyledTooltip
      opacity={1}
      place={place}
      anchorSelect={`#${anchorId}`}
      defaultIsOpen={defaultIsOpen}
      isOpen={open}
      clickable
      style={{
        padding: '0.8rem',
        borderRadius: '0.5rem',
        backgroundColor: color,
        ...style,
      }}
      {...props}
    >
      {children}
    </StyledTooltip>
  )
}

Tooltip.propTypes = {
  anchorId: PropTypes.string,
  color: PropTypes.string,
  place: PropTypes.string,
  open: PropTypes.bool,
  defaultIsOpen: PropTypes.bool,
  children: PropTypes.any,
  style: PropTypes.object,
}

Tooltip.defaultProps = {
  anchorId: '',
  color: theme.palette.primary[4],
  place: null,
  open: undefined,
  defaultIsOpen: false,
  children: null,
  style: {},
}

export default Tooltip
