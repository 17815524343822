import React, { useCallback } from 'react'
import styled from 'styled-components'
import { size } from 'styled-theme'
import { Flex } from '@rebass/grid'
import Text from '../../atoms/Text'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import LoginForm from '../../molecules/LoginForm'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'
import { useBuyMeACoffeeWidget } from '../../../services/buymeacoffee'
import { getErrorMessage } from '../../../utlils/form'
import { getStoredAuth } from '../../../api'
import { login } from '../../../api/auth'
import { useAuth } from '../../../contexts/auth'
import { useLoading } from '../../../contexts/loading'

const loginFormInitialValues = {
  username: '',
  password: '',
}

const StyledViewport = styled(Viewport)`
 padding: 8rem 2rem 3rem;
 align-items: flex-start;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const LoginPage = () => {
  const { setAuth } = useAuth()
  const { setLoading } = useLoading()

  useBuyMeACoffeeWidget()

  const onLogin = useCallback((values, { setFieldError }) => {
    setLoading(true)
    return login(values.username, values.password)
      .then(({ data: user }) => {
        const auth = getStoredAuth()
        const newAuth = { ...auth, user }
        setAuth(newAuth)
      })
      .catch((err) => {
        setFieldError('form', getErrorMessage(err))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [setLoading, setAuth])

  return (
    <StyledViewport>
      <ContentBox>
        <Flex justifyContent="center" mb="2rem">
          <Text palette="primary" bold variant="h1">歡迎回來！</Text>
        </Flex>
        <LoginForm
          onLogin={onLogin}
          initialValues={loginFormInitialValues}
          forgotPasswordPath="/forgot-password"
          registerPath="/register"
        />
      </ContentBox>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default LoginPage
