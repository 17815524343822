import get from 'lodash/get'
import { initialAuthState } from '../../contexts/auth'
import api, { setStoredAuth } from '..'
import { getMe } from '../users'

export const login = (username, password) => {
  const body = {
    username,
    password,
    grant_type: 'password',
  }
  return api.post('/auth/login', body, 'basic')
    .then(({ data: auth }) => {
      setStoredAuth(auth)
      return getMe()
    })
}

export const logout = () => {
  return api.post('/auth/logout', {}, 'bearer')
    .then((res) => {
      if (get(res.data, 'status') === 'success') {
        setStoredAuth(initialAuthState)
      }
      return res
    })
}

export const register = (username, email, password, confirmPassword, extraBody) => {
  const body = {
    username,
    email,
    password,
    confirm_password: confirmPassword,
    ...extraBody,
  }
  return api.post('/auth/register', body, 'basic')
}

export const forgotPassword = (email) => {
  const body = {
    email,
  }
  return api.post('/auth/forgot_password', body, 'basic')
}

export const resetPassword = (email, token, newPassword, confirmPassword) => {
  const body = {
    email,
    new_password: newPassword,
    confirm_password: confirmPassword,
    reset_password_token: token,
  }
  return api.post('/auth/reset_password', body, 'basic')
}

export const changePassword = (password, newPassword, confirmPassword) => {
  const body = {
    password,
    new_password: newPassword,
    confirm_password: confirmPassword,
  }
  return api.post('/auth/change_password', body, 'bearer')
}

export const requestVerifyEmail = () => {
  const body = {}
  return api.post('/auth/request_verify_email', body, 'bearer')
}

export const verifyEmail = (email, token) => {
  const body = {
    email,
    verification_token: token,
  }
  return api.post('/auth/verify_email', body, 'basic')
}
