import React, { useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Box, Flex } from '@rebass/grid'
import styled, { useTheme } from 'styled-components'
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5'
import { palette } from 'styled-theme'
import Text from '../../atoms/Text'
import Image from '../../atoms/Image'
import Button from '../../atoms/Button'
import Modal from '../../molecules/Modal'
import imgMale from '../../../assets/images/male.png'
import { DATE_FORMAT_DATE_TIME_DEFAULT, datetimeFormatter } from '../../../utlils/datetime'
import imgFemale from '../../../assets/images/female.png'

const StyledBox = styled(Flex)`
  background: ${palette('primary', 5)};
  border-radius: 0.5rem;
  padding: 0.8rem 0.8rem 0.4rem;
  position: relative;
  box-shadow: 0 0.125rem 0.125rem ${palette('grayscale', 2)};
  overflow: hidden;
`

const UnreadDot = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${palette('error', 0)};
`

export const USER_MATCH_STATUS_PENDING = 'pending'
export const USER_MATCH_STATUS_ACCEPTED = 'accepted'
export const USER_MATCH_STATUS_REJECTED = 'rejected'

export const getStatusPalette = (status) => {
  switch (status) {
    case USER_MATCH_STATUS_ACCEPTED:
      return { palette: 'success', paletteIndex: 1 }
    case USER_MATCH_STATUS_REJECTED:
      return { palette: 'error', paletteIndex: 1 }
    case USER_MATCH_STATUS_PENDING:
    default:
      return { palette: 'grayscale', paletteIndex: 0 }
  }
}

const getDisplayStatus = (status, respondable) => {
  switch (status) {
    case USER_MATCH_STATUS_PENDING:
      return respondable ? '有人等緊你回覆~' : '等緊對方回覆...'
    case USER_MATCH_STATUS_ACCEPTED:
      return '已配對'
    case USER_MATCH_STATUS_REJECTED:
      return respondable ? '比你拒絕左 ;\'(' : '比人拒絕左 ;\'('
    default:
      return null
  }
}

const UserMatchItem = ({ userMatch, respondable, unread, onAccept, onReject, ...props }) => {
  const { user_match_id, init_user = {}, respond_user = {}, chat_id, status, match_date } = userMatch || {}
  const displayUser = respondable ? init_user : respond_user
  const { display_name, sex, weight, height, date_of_birth, description, phone_number, contact_info } = displayUser || {}
  const { palette: statusPalette, paletteIndex: statusPaletteIndex } = getStatusPalette(status)
  const [confirmModalMode, setConfirmModalMode] = useState(null)
  const theme = useTheme()

  const age = useMemo(() => (
    moment().diff(moment(date_of_birth), 'year')
  ), [date_of_birth])

  const onClickAccept = useCallback(() => {
    setConfirmModalMode('accept')
  }, [])

  const onClickReject = useCallback(() => {
    setConfirmModalMode('reject')
  }, [])

  const onClickConfirm = useCallback(() => {
    if (confirmModalMode === 'accept') {
      onAccept(user_match_id)
    }
    if (confirmModalMode === 'reject') {
      onReject(user_match_id)
    }
    setConfirmModalMode(null)
  }, [confirmModalMode, user_match_id, onAccept, onReject])

  return (
    <StyledBox flexDirection="column" p="2rem" {...props}>
      <Flex justifyContent="space-between" alignItems="flex-start" flexWrap="wrap">
        <Flex alignItems="center" mt="0.4rem">
          <Text variant="h2" palette="primary" paletteIndex={1} bold>{display_name}</Text>
          {!!sex && (
            <Flex ml="0.6rem">
              <Image
                width="1.4rem"
                height="1.4rem"
                src={sex === 'M' ? imgMale : imgFemale}
              />
            </Flex>
          )}
        </Flex>
        <Flex
          style={{
            padding: '0.4rem',
            borderRadius: '0.5rem',
            border: '1px solid',
            borderColor: theme.palette[statusPalette][statusPaletteIndex],
          }}
        >
          <Text palette={statusPalette} paletteIndex={statusPaletteIndex}>{getDisplayStatus(status, respondable)}</Text>
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Flex flexDirection="column">
          {!!date_of_birth && (
            <Flex mt="0.4rem">
              <Text>{`年齡: ${age}`}</Text>
            </Flex>
          )}
          {(!!height || !!weight) && (
            <Flex flexDirection={['column', 'row']} alignItems={['flex-start', 'center']} mt="0.4rem">
              {!!height && (
                <Box mr="2rem">
                  <Text>{`身高: ${height}cm`}</Text>
                </Box>
              )}
              {!!weight && (
              <Box mt={['0.4rem', 0]}>
                <Text>{`體重: ${weight}kg`}</Text>
              </Box>
              )}
            </Flex>
          )}
        </Flex>
        {status === USER_MATCH_STATUS_ACCEPTED && !!chat_id && (
          <Flex flexDirection="column" alignItems="center" mt="0.4rem">
            <Flex style={{ position: 'relative' }}>
              <Button to={`/chat/${chat_id}`} padding="0.25rem">
                <IoChatbubbleEllipsesOutline size={24} />
              </Button>
              {unread && (
                <UnreadDot style={{ position: 'absolute', right: -6, top: -6 }} />
              )}
            </Flex>
            <Box mt="0.1rem">
              <Text variant="body3">聊天室</Text>
            </Box>
          </Flex>
        )}
      </Flex>
      {!!description && (
        <Flex flexDirection="column" mt="0.4rem">
          <Text>個人簡介:</Text>
          <Box mt="0.2rem">
            <Text>{description}</Text>
          </Box>
        </Flex>
      )}
      {/* hide contact section for encouraging chatroom usage */}
      {false && status === USER_MATCH_STATUS_ACCEPTED && (
        <Flex
          mt="0.8rem"
          justifyContent="space-between"
          style={{ padding: '0.6rem', borderRadius: '0.5rem', border: '1px dashed', borderColor: theme.palette.primary[0] }}
        >
          <Box>
            {/* {!!phone_number && (
              <Text>{`電話號碼: ${phone_number}`}</Text>
            )} */}
            {!!contact_info && (
              <Flex flexDirection="column">
                <Text>聯絡方法:</Text>
                <Text>{contact_info}</Text>
              </Flex>
            )}
            {!contact_info && (
              <Text paletteIndex={2}>沒有任何聯絡方法 {';\'('}</Text>
            )}
          </Box>
        </Flex>
      )}
      <Flex mt="0.6rem" justifyContent="flex-end">
        <Text paletteIndex={1} align="right" variant="body2">
          {datetimeFormatter(match_date, DATE_FORMAT_DATE_TIME_DEFAULT)}
        </Text>
      </Flex>
      {respondable && status === USER_MATCH_STATUS_PENDING && (
        <Flex justifyContent="center" flexWrap="wrap">
          <Button variant="text" onClick={onClickAccept}>
            <Text variant="h3" palette="success" paletteIndex={1}>✅ 接受</Text>
          </Button>
          <Button
            variant="text"
            onClick={onClickReject}
            style={{ marginLeft: '0.8rem' }}
          >
            <Text variant="h3" palette="error">❌ 拒絕</Text>
          </Button>
        </Flex>
      )}
      {!!confirmModalMode && (
        <Modal isOpen onClose={() => setConfirmModalMode(null)} style={{ minWidth: '18.5rem' }}>
          <Flex width={1} flexDirection="column" alignItems="center" p="1rem">
            {confirmModalMode === 'accept' && (
              <Text variant="h3" align="center">
                確定要
                <Text variant={null} palette="success" paletteIndex={1} bold>接受</Text>
                佢？<br />
                接受左你地就可以展開激情對話架啦！
              </Text>
            )}
            {confirmModalMode === 'reject' && (
              <Text variant="h3" align="center">
                你認真？<br />確定要
                <Text variant={null} palette="error" bold>拒絕</Text>
                佢？
              </Text>
            )}
            <Flex mt="2rem" width={1} maxWidth="14.5rem">
              <Box flex={1}>
                <Button
                  palette="error"
                  variant="outline"
                  onClick={() => setConfirmModalMode(null)}
                  fullWidth
                >
                  取消
                </Button>
              </Box>
              <Box flex={1} ml="1rem">
                <Button onClick={onClickConfirm} fullWidth>
                  確定
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Modal>
      )}
    </StyledBox>
  )
}

UserMatchItem.propTypes = {
  userMatch: PropTypes.shape({
    respond_user: PropTypes.shape({
      display_name: PropTypes.string,
      sex: PropTypes.string,
      weight: PropTypes.number,
      height: PropTypes.number,
      date_of_birth: PropTypes.number,
      description: PropTypes.string,
      contact_info: PropTypes.string,
    }),
    chat_id: PropTypes.string,
    status: PropTypes.string,
    match_date: PropTypes.number,
  }),
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  respondable: PropTypes.bool,
  unread: PropTypes.bool,
}

UserMatchItem.defaultProps = {
  userMatch: {},
  onAccept: () => {},
  onReject: () => {},
  respondable: false,
  unread: false,
}

export default UserMatchItem
