import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { palette } from 'styled-theme'
import { IoShareOutline } from 'react-icons/io5'
import { MdOutlineInstallMobile, MdOutlineAddBox } from 'react-icons/md'
import Button from '../../atoms/Button'
import Text from '../../atoms/Text'

const IOS_INSTALLED_KEY = 'PWA_IOS_INSTALLED'
const INSTALLATION_REJECTED_KEY = 'PWA_INSTALLATION_REJECTED'
const INSTALLATION_PROMPT_COUNT_KEY = 'PWA_INSTALLATION_PROMPT_COUNT'

const PopUpContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  position: fixed;
  left: 24px;
  right: 24px;
  bottom: 50px;
  z-index: 60;
  max-width: 640px;
  margin: auto;
  border-radius: 0.5rem;
  background: ${palette('primary', 5)}E6;
  box-shadow: 0 1px 5px 2px ${palette('grayscale', 2)};
  backdrop-filter: blur(2px);
`

export const usePwaInstallPrompt = (callback) => {
  useEffect(() => {
    const pwaBeforeInstallListener = window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      callback(e)
    })
    return () => {
      window.removeEventListener('beforeinstallprompt', pwaBeforeInstallListener)
    }
  }, [callback])
}

const ProgressiveAppHandler = ({ promptInterval }) => {
  const [installPromptEvent, setInstallPromptEvent] = useState(null)
  const [iosPromptOpen, setIosPromptOpen] = useState(false)

  const getInstallationPromptCount = useCallback(() => {
    const promptCount = Number(localStorage.getItem(INSTALLATION_PROMPT_COUNT_KEY)) || 0
    return promptCount
  }, [])

  const getInstallationRejected = useCallback(() => {
    const isRejected = localStorage.getItem(INSTALLATION_REJECTED_KEY) === 'true'
    return isRejected
  }, [])

  const getIosInstalled = useCallback(() => {
    const isInstalled = localStorage.getItem(IOS_INSTALLED_KEY) === 'true'
    return isInstalled
  }, [])

  const rejectPwaInstall = useCallback(() => {
    const installationPromptCount = getInstallationPromptCount()
    localStorage.setItem(INSTALLATION_REJECTED_KEY, true)
    localStorage.setItem(INSTALLATION_PROMPT_COUNT_KEY, installationPromptCount + 1)
    setInstallPromptEvent(null)
    setIosPromptOpen(false)
  }, [getInstallationPromptCount])

  const clickIosInstalled = useCallback(() => {
    localStorage.setItem(IOS_INSTALLED_KEY, true)
    setIosPromptOpen(false)
  }, [])

  const handlePwaPopUp = useCallback((openPopUpFunc) => {
    const installationPromptCount = getInstallationPromptCount()
    const installationRejected = getInstallationRejected()
    if (!installationRejected) {
      openPopUpFunc()
    } else if (installationPromptCount <= promptInterval * 2) {
      if (installationPromptCount % promptInterval === 0) {
        localStorage.setItem(INSTALLATION_REJECTED_KEY, false)
        openPopUpFunc()
      } else {
        localStorage.setItem(INSTALLATION_PROMPT_COUNT_KEY, installationPromptCount + 1)
      }
    }
  }, [promptInterval, getInstallationPromptCount, getInstallationRejected])

  usePwaInstallPrompt(useCallback((e) => {
    handlePwaPopUp((() => setInstallPromptEvent(e)))
  }, [handlePwaPopUp]))

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    if (/iphone|ipad|ipod/.test(userAgent)) {
      const isIosInstalled = getIosInstalled()
      const isInStandaloneMode = ('standalone' in window.navigator) && window.navigator.standalone
      if (!isInStandaloneMode && !isIosInstalled) {
        handlePwaPopUp(() => setIosPromptOpen(true))
      }
    }
  }, [getIosInstalled, handlePwaPopUp])

  return (installPromptEvent || iosPromptOpen) ? (
    <PopUpContainer>
      <Text>不如裝個App落黎方便啲？</Text>
      {installPromptEvent ? (
        <Flex mt="1rem">
          <Button onClick={() => installPromptEvent.prompt()}>
            一鍵下載<MdOutlineInstallMobile size={20} style={{ marginLeft: 4 }} />
          </Button>
          <Button palette="error" onClick={rejectPwaInstall} style={{ marginLeft: '0.5rem' }}>
            再諗諗
          </Button>
        </Flex>
      ) : (
        <>
          <Flex flexDirection="column" alignItems="flex-start" mt="1rem">
            <Flex alignItems="center">
              <Text>1. 先按</Text>
              <IoShareOutline size={28} color="#007AFF" style={{ marginLeft: '0.25rem', marginRight: '0.25rem' }} />
            </Flex>
            <Flex alignItems="center" mt="0.2rem">
              <Text>2. 碌落去揀</Text>
              <Flex
                alignItems="center"
                marginLeft="0.5rem"
                padding="0.25rem 0.5rem"
                style={{ border: '1px solid', borderRadius: '0.25rem' }}
              >
                <Text>加入主畫面</Text>
                <MdOutlineAddBox size={24} style={{ marginLeft: '0.25rem' }} />
              </Flex>
            </Flex>
          </Flex>
          <Flex mt="1rem">
            <Button onClick={clickIosInstalled}>裝左啦</Button>
            <Button palette="error" onClick={rejectPwaInstall} style={{ marginLeft: '0.5rem' }}>
              再諗諗
            </Button>
          </Flex>
        </>
      )}
    </PopUpContainer>
  ) : null
}

ProgressiveAppHandler.propTypes = {
  promptInterval: PropTypes.number,
}

ProgressiveAppHandler.defaultProps = {
  promptInterval: 5,
}

export default ProgressiveAppHandler
