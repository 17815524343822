import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { size } from 'styled-theme'
import Text from '../../atoms/Text'
import Button from '../../atoms/Button'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import EditEventForm from '../../molecules/EditEventForm'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'
import { createEvent, deleteEvent, getEvent, updateEvent } from '../../../api/events'
import { getUsers } from '../../../api/users'
import { handleErrorResponse } from '../../../utlils/general'
import { getErrorMessage } from '../../../utlils/form'
import { useLoading } from '../../../contexts/loading'
import { useAuth } from '../../../contexts/auth'

const StyledViewport = styled(Viewport)`
  padding: 4.5rem 3rem 3rem;
  align-items: flex-start;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const EditEventPage = () => {
  const [currentEvent, setCurrentEvent] = useState(null)
  const [organizers, setOrganizers] = useState([])
  const { setAuth } = useAuth()
  const { setLoading } = useLoading()
  const { eventId } = useParams()
  const navigate = useNavigate()

  const fetchEventFromApi = useCallback(() => {
    if (!eventId) {
      setCurrentEvent(null)
      return Promise.resolve(null)
    }
    const eventParams = {}
    setLoading(true)
    return getEvent(eventId, eventParams)
      .then(({ data: event }) => {
        setCurrentEvent(event)
      })
      .catch(((err) => {
        handleErrorResponse(err, setAuth)
      }))
      .finally(() => {
        setLoading(false)
      })
  }, [eventId, setLoading, setAuth])

  const fetchOrganizersFromApi = useCallback(() => {
    const userParams = {
      role: 2,
    }
    return getUsers(userParams)
      .then(({ data: users }) => {
        setOrganizers(users)
      })
      .catch(((err) => {
        handleErrorResponse(err, setAuth)
      }))
  }, [setAuth])

  const createOrUpdateEventToApi = useCallback((values, { setFieldError }) => {
    const upsertBody = {
      name: values.name,
      creator_id: values.creator_id,
      start_time: values.start_time ? moment(values.start_time).valueOf() : null,
      description: values.description,
      active: values.active,
    }
    setLoading(true)
    const upsertEvent = currentEvent?.event_id ? updateEvent(currentEvent.event_id, upsertBody) : createEvent(upsertBody)
    return upsertEvent
      .then(({ data: upsertedEvent }) => {
        if (currentEvent) {
          setCurrentEvent(upsertedEvent)
        } else {
          navigate(`/events/${upsertedEvent?.event_id}`)
        }
      })
      .catch(((err) => {
        setFieldError('form', getErrorMessage(err))
        handleErrorResponse(err, setAuth)
      }))
      .finally(() => {
        setLoading(false)
      })
  }, [currentEvent, navigate, setLoading, setAuth])

  const deleteEventToApi = useCallback(() => {
    if (!currentEvent) {
      return Promise.resolve(null)
    }
    if (!window.confirm(`係咪諗清楚要刪除 "${currentEvent?.name}" ?`)) {
      return Promise.resolve(null)
    }
    setLoading(true)
    return deleteEvent(currentEvent?.event_id)
      .then(() => {
        navigate('/create-event')
      })
      .catch(((err) => {
        handleErrorResponse(err, setAuth)
      }))
      .finally(() => {
        setLoading(false)
      })
  }, [currentEvent, navigate, setLoading, setAuth])

  useEffect(() => {
    fetchEventFromApi()
  }, [fetchEventFromApi])

  useEffect(() => {
    fetchOrganizersFromApi()
  }, [fetchOrganizersFromApi])

  return (
    <StyledViewport>
      <ContentBox>
        <Flex justifyContent="center" mb="2rem">
          <Text palette="primary" bold variant="h1">活動資料</Text>
        </Flex>
        <EditEventForm
          event={currentEvent}
          organizers={organizers}
          onSave={createOrUpdateEventToApi}
        />
        {!!currentEvent?.event_id && (
          <Flex mt="4rem" justifyContent="flex-end">
            <Button
              palette="error"
              onClick={deleteEventToApi}
            >
              刪除
            </Button>
          </Flex>
        )}
      </ContentBox>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default EditEventPage
