import React, { useCallback } from 'react'
import get from 'lodash/get'
import { Formik } from 'formik'
import { Box, Flex } from '@rebass/grid'
import styled from 'styled-components'
import { size } from 'styled-theme'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Text from '../../atoms/Text'
import Input from '../../atoms/Input'
import Button from '../../atoms/Button'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'
import { resetPassword } from '../../../api/auth'
import { getErrorMessage } from '../../../utlils/form'
import { useLoading } from '../../../contexts/loading'

const StyledViewport = styled(Viewport)`
 padding: 8rem 2rem 3rem;
 align-items: flex-start;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const formValidation = (values) => {
  const { new_password, confirm_password } = values
  const errors = {}
  if (!new_password) {
    errors.new_password = '必須填寫'
  }
  if (!confirm_password) {
    errors.confirm_password = '必須填寫'
  } else if (new_password !== confirm_password) {
    errors.confirm_password = '密碼不一致'
  }
  return errors
}

const formInitialValues = {
  new_password: '',
  confirm_password: '',
}

const ResetPasswordPage = () => {
  const navigate = useNavigate()
  const { setLoading } = useLoading()
  const [searchParams] = useSearchParams()

  const email = searchParams.get('email')
  const token = searchParams.get('t')

  const onSubmit = useCallback((values, { setFieldError }) => {
    setLoading(true)
    return resetPassword(email, token, values.new_password, values.confirm_password)
      .then(({ data }) => {
        alert('密碼已被重設！\n請使用新密碼重新登入')
        navigate('/login')
      })
      .catch((err) => {
        setFieldError('form', getErrorMessage(err))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [email, token, navigate, setLoading])

  return (
    <StyledViewport>
      <ContentBox>
        <Flex justifyContent="center" mb="2rem">
          <Text palette="primary" bold variant="h1">重設密碼</Text>
        </Flex>
        <Formik
          validate={formValidation}
          initialValues={formInitialValues}
          onSubmit={onSubmit}
        >
          {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Text palette="primary">E-mail</Text>
                <Box mt="0.4rem" mb="1.5rem">
                  <Input
                    name="email"
                    placeholder="E-mail *"
                    value={email || ''}
                    invalid={!email}
                    readOnly
                  />
                </Box>
                <Box mt="0.2rem">
                  <Input
                    name="new_password"
                    placeholder="新密碼 *"
                    type="password"
                    value={get(values, 'new_password', '')}
                    onChange={handleChange('new_password')}
                    onBlur={handleBlur('new_password')}
                    invalid={touched.new_password && !!errors.new_password}
                  />
                  <Box ml="0.8rem">
                    <Text variant="body3" palette="error">{touched.new_password && !!errors.new_password ? errors.new_password : ' '}</Text>
                  </Box>
                </Box>
                <Box mt="0.2rem">
                  <Input
                    name="confirm_password"
                    placeholder="確認新密碼 *"
                    type="password"
                    value={get(values, 'confirm_password', '')}
                    onChange={handleChange('confirm_password')}
                    onBlur={handleBlur('confirm_password')}
                    invalid={touched.confirm_password && !!errors.confirm_password}
                  />
                  <Box ml="0.8rem">
                    <Text variant="body3" palette="error">{touched.confirm_password && !!errors.confirm_password ? errors.confirm_password : ' '}</Text>
                  </Box>
                </Box>
                {!!errors.form && (
                  <Box ml="0.8rem">
                    <Text palette="error">{errors.form}</Text>
                  </Box>
                )}
                <Flex mt="2rem">
                  <Button
                    type="submit"
                    disabled={!values.confirm_password || !values.new_password || isSubmitting}
                    fullWidth
                  >
                    確定
                  </Button>
                </Flex>
              </form>
            )
          }}
        </Formik>
      </ContentBox>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default ResetPasswordPage
