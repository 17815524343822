import get from 'lodash/get'

export const isValidEmail = (email) => {
  const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  return (emailFormat).test(email)
}

export const getErrorMessage = (error) => {
  const message = get(error, 'response.data.error_description')
    || get(error, 'response.data.message')
    || get(error, 'response.data.error')
    || JSON.stringify(get(error, 'response.data'))
    || get(error, 'message')
  return message
}
