import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';
import { Formik } from 'formik'
import { useTheme } from 'styled-components'
import { Box, Flex } from '@rebass/grid'
import Input from '../../atoms/Input'
import Text from '../../atoms/Text'
import Select from '../../atoms/Select'
import Checkbox from '../../atoms/Checkbox'
import Button from '../../atoms/Button'
import { datetimeFormatter } from '../../../utlils/datetime'

const formValidation = (values) => {
  const { name, description } = values
  const errors = {}
  if (!name) {
    errors.name = '必須填寫'
  }
  if (!description) {
    errors.description = '必須填寫'
  }
  return errors
}

const EditEventForm = ({ event, organizers, onSave, ...props }) => {
  const theme = useTheme()

  const initialValues = useMemo(() => ({
    name: event?.name || '',
    creator_id: event?.creator_id || '',
    start_time: event?.start_time ? datetimeFormatter(event.start_time, 'YYYY-MM-DD HH:mm') : '',
    description: event?.description || '',
    active: event ? event.active : true,
  }), [event])

  const organizerOptions = useMemo(() => organizers.map((organizer) => {
    const { user_id, display_name } = organizer
    return {
      label: display_name,
      value: user_id,
    }
  }), [organizers])

  return (
    <Formik
      validate={formValidation}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSave}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, touched, dirty }) => {
        const renderFormField = (fieldName, label, type, extraProps) => (
          <Box mt="0.6rem">
            <Input
              name={fieldName}
              label={get(values, fieldName) ? label : null}
              placeholder={label}
              value={get(values, fieldName) || ''}
              onChange={handleChange(fieldName)}
              onBlur={handleBlur(fieldName)}
              invalid={touched[fieldName] && !!errors[fieldName]}
              type={type}
              {...extraProps}
            />
            <Box ml="0.8rem">
              <Text variant="body3" palette="error">{touched[fieldName] && !!errors[fieldName] ? errors[fieldName] : ' '}</Text>
            </Box>
          </Box>
        )
        return (
          <form onSubmit={handleSubmit}>
            {renderFormField('name', '活動名稱 *', 'text')}
            <Box mt="0.6rem">
              <Select
                label={get(values, 'creator_id') ? '主辦單位' : null}
                placeholder="主辦單位"
                value={values.creator_id}
                options={organizerOptions}
                onChange={(v) => setFieldValue('creator_id', v)}
                clearable
              />
              <Box ml="0.8rem">
                <Text variant="body3" palette="error">{touched.creator_id && !!errors.creator_id ? errors.creator_id : ' '}</Text>
              </Box>
            </Box>
            {renderFormField('start_time', '日期', 'datetime-local', { label: '日期' })}
            {renderFormField('description', '簡介 *', 'textarea', { rows: 20 })}
            <Flex
              my="0.4rem"
              alignItems="center"
              style={{ border: '2px dashed', borderColor: theme.palette.secondary[0], padding: '1rem', borderRadius: '0.5rem' }}
            >
              <Text palette="primary" bold style={{ whiteSpace: 'nowrap' }}>啟用</Text>
              <Flex ml="2rem" alignItems="center">
                <Checkbox
                  name="active"
                  checked={values.active}
                  onChange={(v) => setFieldValue('active', v)}
                  onBlur={handleBlur('active')}
                  invalid={touched.active && !!errors.active}
                />
              </Flex>
            </Flex>
            {!!errors.form && (
              <Text palette="error">{errors.form}</Text>
            )}
            <Flex mt="2rem">
              <Button
                type="submit"
                disabled={!dirty || isSubmitting}
                fullWidth
              >
                儲存
              </Button>
            </Flex>
          </form>
        )
      }}
    </Formik>
  )
}

EditEventForm.propTypes = {
  event: PropTypes.shape({
    name: PropTypes.string,
    creator_id: PropTypes.string,
    start_time: PropTypes.number,
    description: PropTypes.string,
    active: PropTypes.bool,
  }),
  organizers: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.string,
      username: PropTypes.string,
      display_name: PropTypes.string,
    }),
  ),
  onSave: PropTypes.func,
}

EditEventForm.defaultProps = {
  event: null,
  organizers: [],
  onSave: () => {},
}

export default EditEventForm
