import io from 'socket.io-client'
import config from '../config'

const BASE_URL = config.apiUrl
const SOCKET_PATH = '/socket'

export const SOCKET_EVENT_CONNECT = 'connect'
export const SOCKET_EVENT_DISCONNECT = 'disconnect'
export const SOCKET_EVENT_NEW_MESSAGE = 'new_message'
export const SOCKET_EVENT_JOIN_CHAT = 'join_chat'
export const SOCKET_EVENT_JOIN_CHATS = 'join_chats'
export const SOCKET_EVENT_LEAVE_CHAT = 'leave_chat'
export const SOCKET_EVENT_LEAVE_CHATS = 'leave_chats'
export const SOCKET_EVENT_SEND_MESSAGE = 'send_message'
export const SOCKET_EVENT_MESSAGE_QUOTA_UPDATE = 'message_quota_update'
export const SOCKET_EVENT_USER_STATE_UPDATE = 'user_state_update'
export const SOCKET_EVENT_USER_CONNECTED = 'user_connected'

export const createSocket = () => {
  const socket = io(BASE_URL, {
    reconnectionDelayMax: 10000,
    withCredentials: false,
    path: SOCKET_PATH,
  })
  return socket
}
