import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import styled from 'styled-components'
import { palette, size } from 'styled-theme'
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom'
import { Box, Flex } from '@rebass/grid'
import { RiRobot2Line, RiRobot2Fill, RiHeartsLine, RiHeartsFill, RiUserLine, RiUserFill, RiLogoutBoxRLine } from 'react-icons/ri'
import { MdInstallMobile } from 'react-icons/md'
import Text from './components/atoms/Text'
import Image from './components/atoms/Image'
import Button from './components/atoms/Button'
import ProgressiveAppHandler, { usePwaInstallPrompt } from './components/molecules/ProgressiveAppHandler'
import { handleErrorResponse } from './utlils/general'
import { initialAuthState, useAuth } from './contexts/auth'
import { useLoading } from './contexts/loading'
import { getMe } from './api/users'
import { logout } from './api/auth'
import { getStoredAuth } from './api'
import routes from './routes'
import './App.css'

const menuItems = [
  {
    label: '對話',
    Icon: RiRobot2Fill,
    IconActive: RiRobot2Line,
    path: '/',
  },
  {
    label: '配對',
    Icon: RiHeartsFill,
    IconActive: RiHeartsLine,
    path: '/matches',
  },
]

const AppContainer = styled(Flex)`
  background: ${palette('grayscale', 4)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Header = styled.header`
  background: ${palette('primary', 0)};
  padding: 0.25rem 1rem;
  position: fixed;
  height: 30vh;
  top: 0;
  left: 0;
  right: 0;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 0.5rem;
  }
`

const HeaderButton = styled(Button)`
  padding: 0.5rem;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 0.25rem;
  }
`
const HeaderButtonText = styled(Text)`
  margin-left: 0.4rem;
  @media only screen and (max-width: ${size('tablet')}) {
    display: none;
  }
`

const ProfileButton = styled(HeaderButton)`
  @media only screen and (max-width: ${size('mobile')}) {
    max-width: 5rem;
  }
`

const DesktopImage = styled(Image)`
  @media only screen and (max-width: ${size('mobile')}) {
    display: none;
  }
`

const MobileImage = styled(Image)`
  display: none;
  @media only screen and (max-width: ${size('mobile')}) {
    display: initial;
  }
`

const AuthRedirectior = ({ required, to, children }) => {
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const { auth } = useAuth()

  if (required && Object.prototype.hasOwnProperty.call(required, 'auth')) {
    if (required.auth) {
      if (!auth.user) {
        const locationStr = `${location.pathname}${location.search}`
        return (
          <Navigate
            to={{
              pathname: to,
              ...(locationStr !== '/' ? {
                search: `?redirect=${locationStr}`,
              } : {}),
            }}
            replace
          />
        )
      }
    } else if (auth.user) {
      return <Navigate to={searchParams.get('redirect') || '/'} replace />
    }
  }
  return children
}

AuthRedirectior.propTypes = {
  required: PropTypes.shape({
    auth: PropTypes.bool,
  }),
  to: PropTypes.string,
  children: PropTypes.node,
}

const App = () => {
  const [installPwaPromptEvent, setInstallPwaPromptEvent] = useState(null)
  const { auth, setAuth } = useAuth()
  const { setLoading } = useLoading()
  const location = useLocation()

  usePwaInstallPrompt(setInstallPwaPromptEvent)

  const onLogout = useCallback(() => {
    setLoading(true)
    return logout()
      .then(({ data }) => {
        if (get(data, 'status') !== 'success') {
          throw new Error('登出發生錯誤')
        }
        setAuth(initialAuthState)
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [setLoading, setAuth])

  useEffect(() => {
    if (get(getStoredAuth(), 'access_token')) {
      setLoading(true)
      getMe()
        .then(({ data: user }) => {
          const updatedAuth = { ...getStoredAuth(), user }
          setAuth(updatedAuth)
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [setLoading, setAuth])

  return (
    <AppContainer>
      <Header>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <DesktopImage src="/logo.png" height="3.75rem" alt="Crush" />
            <MobileImage src="/logo_simple.png" height="2.75rem" alt="Crush" />
          </Flex>
          {!!auth.user && (
            <>
              <Flex alignItems="center">
                {menuItems.map((menuItem, mi) => {
                  const { label, path, Icon, IconActive } = menuItem
                  const isActive = location.pathname === path
                  return (
                    <Box key={mi} ml={mi > 0 ? '0.2rem' : 0}>
                      <HeaderButton
                        variant={isActive ? 'outline' : 'text'}
                        palette="grayscale"
                        to={path}
                      >
                        {isActive && !!IconActive ? (
                          <IconActive size={28} />
                        ) : (
                          <Icon size={28} />
                        )}
                        <HeaderButtonText>{label}</HeaderButtonText>
                      </HeaderButton>
                    </Box>
                  )
                })}
                {installPwaPromptEvent && (
                  <Box ml="0.2rem">
                    <HeaderButton
                      variant="text"
                      palette="grayscale"
                      onClick={() => installPwaPromptEvent.prompt()}
                    >
                      <MdInstallMobile size={28} />
                      <HeaderButtonText>下載</HeaderButtonText>
                    </HeaderButton>
                  </Box>
                )}
              </Flex>
              <Flex alignItems="center">
                <Box mr="0.2rem">
                  <ProfileButton
                    variant={location.pathname === '/profile' ? 'outline' : 'text'}
                    palette="grayscale"
                    to="/profile"
                  >
                    {location.pathname === '/profile' ? (
                      <RiUserLine size={28} />
                    ) : (
                      <RiUserFill size={28} />
                    )}
                    <HeaderButtonText>用戶</HeaderButtonText>
                  </ProfileButton>
                </Box>
                <HeaderButton palette="grayscale" onClick={onLogout}>
                  <RiLogoutBoxRLine size={28} />
                  <HeaderButtonText palette="white">登出</HeaderButtonText>
                </HeaderButton>
              </Flex>
            </>
          )}
        </Flex>
      </Header>
      <Flex flex="1 1 0" width={1} justifyContent="center" style={{ overflow: 'scroll' }}>
        <Routes>
          {routes.map((route) => {
            const { path, element } = route
            return (
              <Route
                key={path}
                path={path}
                element={(
                  <AuthRedirectior required={route.required} to="/login">
                    {element}
                  </AuthRedirectior>
                )}
              />
            )
          })}
        </Routes>
      </Flex>
      <ProgressiveAppHandler />
    </AppContainer>
  )
}

export default App
