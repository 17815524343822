import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@rebass/grid'
import { LuSendHorizonal } from 'react-icons/lu'
import { handleErrorResponse } from '../../../utlils/general'
import Input from '../../atoms/Input'
import Button from '../../atoms/Button'

export const ERROR_QUOTA_EXCEEDED = 'QUOTA_EXCEEDED'

const MessageSender = ({ onSend, disabled, ...props }) => {
  const [message, setMessage] = useState('')

  const onSendMessage = useCallback(async () => {
    try {
      await onSend(message)
      setMessage('')
    } catch (err) {
      if (err.message === ERROR_QUOTA_EXCEEDED) {
        return
      }
      handleErrorResponse(err, () => {}, '系統有啲野出錯左！\n請等陣再試>.<')
    }
  }, [message, onSend])

  return (
    <Flex {...props}>
      <Flex flex={1}>
        <Input
          placeholder="輸入訊息..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          readOnly={disabled}
          onKeyUp={(e) => {
            if (message && e.keyCode === 13) {
              return onSendMessage()
            }
            return false
          }}
        />
      </Flex>
      <Flex ml="0.25rem">
        <Button
          padding="0.625rem"
          onClick={onSendMessage}
          disabled={disabled || !message}
        >
          <LuSendHorizonal size={24} />
        </Button>
      </Flex>
    </Flex>
  )
}

MessageSender.propTypes = {
  onSend: PropTypes.func,
  disabled: PropTypes.bool,
}

MessageSender.defaultProps = {
  onSend: () => {},
  disabled: false,
}

export default MessageSender
