import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { ifNotProp, ifProp, prop } from 'styled-tools'
import { palette as styledPalette } from 'styled-theme'

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${ifProp('disabled', 'auto', 'pointer')};
  outline: 0;
  padding: ${prop('padding', '0.8rem 1rem')};
  font-size: 1rem;
  line-height: 1.2;
  border-radius: 0.5rem;
  transition: ease 0.3s;
  color: ${({ palette }) => ifProp({ variant: 'solid' }, styledPalette('white', 0), ifProp('disabled', styledPalette('grayscale', 4), styledPalette(palette || 'primary', 0)))};
  ${ifProp({ variant: 'solid' }, css`
    background: ${({ palette }) => ifProp('disabled', styledPalette('grayscale', 4), styledPalette(palette || 'primary', 0))};
  `, css`
    background: transparent;
  `)};
  ${ifProp({ variant: 'text' }, css`
    border: 0.125rem solid transparent;
  `, css`
    border: 0.125rem solid ${({ palette }) => ifProp('disabled', styledPalette('grayscale', 4), styledPalette(palette || 'primary', 0))};
  `)}
  ${ifNotProp('disabled', css`
    :hover {
      ${ifNotProp({ variant: 'text' }, css`
        border-color: ${({ palette }) => styledPalette(palette || 'primary', 1)};
      `)}
      ${ifProp({ variant: 'solid' }, css`
        background: ${({ palette }) => styledPalette(palette || 'primary', 1)};
      `, css`
        color: ${({ palette }) => styledPalette(palette || 'primary', 1)};
      `)}
    }
    :active {
      ${ifProp({ variant: 'solid' }, css`
        background: ${({ palette }) => styledPalette(palette || 'primary', 2)};
        border-color: ${({ palette }) => styledPalette(palette || 'primary', 2)};
      `, css`
        color: ${({ palette }) => styledPalette(palette || 'primary', 2)};
      `)}
      ${ifProp({ variant: 'outline' }, css`
        background: ${({ palette }) => styledPalette(palette || 'primary', 5)}1A;
        border-color: ${({ palette }) => styledPalette(palette || 'primary', 2)};
      `)}
    }
  `)}
  ${ifProp('fullWidth', css`
    display: block;
    width: 100%;
  `)}
`

const Button = ({ onClick, variant, to, href, palette, padding, disabled, fullWidth, ...props }) => {
  const navigate = useNavigate()

  const newOnClick = useCallback((e) => {
    if (to) {
      navigate(to)
    }
    if (href) {
      window.open(href, '_blank', 'noopener, noreferrer')
    }
    return onClick(e)
  }, [to, href, onClick, navigate])

  return (
    <StyledButton
      type="button"
      onClick={newOnClick}
      variant={variant}
      palette={palette}
      padding={padding}
      disabled={disabled}
      fullWidth={fullWidth}
      {...props}
    />
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  href: PropTypes.string,
  variant: PropTypes.oneOf(['solid', 'outline', 'text']),
  palette: PropTypes.string,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
}

Button.defaultProps = {
  onClick: () => {},
  to: null,
  href: null,
  variant: 'solid',
  palette: 'primary',
  padding: '0.8rem 1rem',
  disabled: false,
  fullWidth: false,
}

export default Button
