import api from '..'

export const getUsers = (params) => {
  return api.get('/users', params, 'bearer')
}

export const getMe = (params) => {
  return api.get('/users/me', params, 'bearer')
}

export const getUser = (username, params) => {
  return api.get(`/users/${username}`, params, 'bearer')
}

export const updateUser = (username, body) => {
  return api.put(`/users/${username}`, body, 'bearer')
}

export const getMyUserState = (params) => {
  return api.get('/user-states/mine', params, 'bearer')
}

export const updateMyUserState = (body) => {
  return api.put('/user-states/mine/mode', body, 'bearer')
}
